import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/docs-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "nice60"
    }}>{`nice!60`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.87116564417178%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQL/xAAVAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAABkxvE3SMJP//EABsQAQABBQEAAAAAAAAAAAAAAAIAAQMREhMi/9oACAEBAAEFAmVvhS1Twxl8papqf//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPwHEf//EABYRAQEBAAAAAAAAAAAAAAAAAAEAIf/aAAgBAgEBPwEcl2//xAAYEAEBAQEBAAAAAAAAAAAAAAAAMaERIf/aAAgBAQAGPwK6uvVVx//EABwQAAIDAAMBAAAAAAAAAAAAAAARASExcYGh0f/aAAgBAQABPyFyoeCVZM2lvkfzToinyaJ2f//aAAwDAQACAAMAAAAQUN//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAEDAQE/EFaw5f/EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQIBAT8QTGjqh//EACEQAAEDAgcBAAAAAAAAAAAAAAEAESExQVFhcYGhsdHw/9oACAEBAAE/ECG0IqNmDoEg9t9Q1Q5IPZX7gIlbVEJfXKzcC7Mv/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "nice!60",
          "title": "nice!60",
          "src": "/static/e04b30283910138583e0ca2da1676529/6aca1/nice60.jpg",
          "srcSet": ["/static/e04b30283910138583e0ca2da1676529/d2f63/nice60.jpg 163w", "/static/e04b30283910138583e0ca2da1676529/c989d/nice60.jpg 325w", "/static/e04b30283910138583e0ca2da1676529/6aca1/nice60.jpg 650w", "/static/e04b30283910138583e0ca2da1676529/7c09c/nice60.jpg 975w", "/static/e04b30283910138583e0ca2da1676529/01ab0/nice60.jpg 1300w", "/static/e04b30283910138583e0ca2da1676529/a16a0/nice60.jpg 1616w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`The nice!60 is a standard 60% ANSI hotswap PCB offering BLE using the nRF52840 chip.
The nice!60 offers extremely long battery life as long as the underglow LEDs are kept off. `}</p>
    <p>{`To get your nice!60 up and running check out `}<a parentName="p" {...{
        "href": "/docs/nice-60/getting-started"
      }}><strong parentName="a">{`Getting Started`}</strong></a>{`.`}</p>
    <p>{`If you're a hardware designer interested to learn more about the schematic check out the `}<a parentName="p" {...{
        "href": "/docs/nice-60/schematic"
      }}><strong parentName="a">{`Schematic`}</strong></a>{`.`}</p>
    <h2 {...{
      "id": "recommended-batteries"
    }}>{`Recommended batteries`}</h2>
    <p>{`To run your nice!60 wirelessly, you need a battery.
In specific, you need a 3.7v rechargeable lithium battery that is `}<strong parentName="p">{`at least 500mAh large`}</strong>{`.
Note when choosing a battery, the recharge rate is 500mA, so a 2,000mAh battery will take about 4 hours to charge.
You'll want a battery that uses a 2-pin JST-PH (2.00mm) connector to properly attach the battery to the nice!60.
While this is the most common connector type for hobbyist lithium batteries, double check that it's the right kind.`}</p>
    <h2 {...{
      "id": "bootloader"
    }}>{`Bootloader`}</h2>
    <p>{`The nice!60 uses the `}<a parentName="p" {...{
        "href": "https://github.com/Nice-Keyboards/Adafruit_nRF52_Bootloader"
      }}>{`Adafruit nRF52 Bootloader`}</a>{`. Check the releases of our fork for the most up to date `}<inlineCode parentName="p">{`_nosd.hex`}</inlineCode>{` variant.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      